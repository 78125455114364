import React, { Component } from "react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { uuidValidate } from "uuid-validate";
import MetaTags from "react-meta-tags";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withTranslation } from "react-i18next";
import "./css/landing.css"
class LandingPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      templateData: "",
      formVisible: true,
      thankYouMessage: '',
    };

    this.formRef = React.createRef();
    this.handleFormSubmit = this.handleFormSubmit.bind(this);
  }

  componentDidMount() {
    const { match: { params }
    } = this.props;
    
    const projectId = params.projectId;

    trackSource();
    axios
      .get(
        `https://rscoreapi.azurewebsites.net/api/ProjectLandingPage/byprojectguid?guid=${projectId}`
      )
      .then((res) => {
        this.setState({ templateData: res.data });
      })
      .catch((error) => {
        console.error("There was an error fetching the data!", error);
      });
  }

  componentDidUpdate() {
    if (this.formRef.current) {
      this.formRef.current.addEventListener("submit", this.handleFormSubmit);
    }
  }

  componentWillUnmount() {
    if (this.formRef.current) {
      this.formRef.current.removeEventListener("submit", this.handleFormSubmit);
    }
  }

  handleFormSubmit(event) {
    event.preventDefault();
    const formData = new FormData(event.target);

    const datt = {
      projectCampaignId: 3100,
      agentId: this.state.templateData.agentId,
      projectCampaignGuid:this.props.match.params.projectId,
      campaignProjectId: this.state.templateData.landingPageProjectId,
      campaignProjectGuid:this.props.match.params.projectId,
      leadSourceId:3,
      firstName: formData.get("first_name"),
      lastName: formData.get("last_name"),
      email: formData.get("email"),
      mobile: formData.get("phone_number"),
      withAgent: formData.get("isrealtor") === "true" ? "true" : "false"
    };

    toast.loading("Please wait...");
    axios
      .post("https://rscoreapi.azurewebsites.net/api/ProjectCampaignLead", datt)
      .then((response) => {
        toast.dismiss();

        if (response.data.success) {
          this.setState({
            formVisible: false,
            thankYouMessage:`We will contact you shortly to go over the next steps. If you have questions feel free to call at}.`,
          });
        } else {
          response.data.validationErrors.forEach((error) => {
            toast.error(error);
          });
        }
      })
      .catch((error) => {
        toast.dismiss();
        toast.error("Error submitting the form");
        console.error("There was an error submitting the form!", error);
      });
  }

  render() {
    const { templateData } = this.state;
    const { formVisible, thankYouMessage } = this.state;
    return (
      <React.Fragment>
        <ToastContainer autoClose={2000} />
        {templateData.landingPageTitle && (
          <MetaTags>
            <title>{templateData.landingPageTitle} | Realty Space</title>
          </MetaTags>
        )}
         {formVisible ? (
        <div
          ref={this.formRef}
          dangerouslySetInnerHTML={{
            __html: templateData.landingPageFullContent || "Loading content..."
          }}
        />
      ) : (
        <div className="text-center">
          <h4 className="text-danger">Thank you </h4>
       
       <h5>Thank you for submitting the form. </h5>
       <h5>
          {thankYouMessage}
       </h5>
        </div>
      )}
      </React.Fragment>
    );
  }
}

function trackSource() {
  const sourceInfo = {
    source: detectSource(),
    timestamp: new Date().toISOString(),
    browser: navigator.userAgent,
    platform: getPlatform()
  };
  console.log(sourceInfo);
  // fetch("/track-source", {
  //   method: "POST",
  //   headers: { "Content-Type": "application/json" },
  //   body: JSON.stringify(sourceInfo)
  // }).catch((error) => console.error("Error tracking source:", error));
}

function detectSource() {
  const referrer = document.referrer;
  if (referrer.includes("google.com")) return "google";
  if (referrer.includes("facebook.com")) return "facebook";
  const urlParams = new URLSearchParams(window.location.search);
  return urlParams.get("source") || "direct";
}

function getPlatform() {
  const platform = navigator.platform || "unknown";
  return platform.toLowerCase();
}
LandingPage.propTypes = {
  t: PropTypes.any,
  match: PropTypes.object,
  history:PropTypes.object
};
export default connect()(withTranslation()(LandingPage));

